<template>
    <div>
        <froala :tag="'textarea'" :config="config" v-model="content"></froala>
    </div>
</template>

<script>
    import VueFroala from 'vue-froala-wysiwyg';
    const tool = [
        'undo',
        'redo',
        'clearFormatting',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontFamily',
        'fontSize',
        'textColor',
        'color',
        'backgroundColor',
        'inlineStyle',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'quote',
        'insertLink',
        'insertImage',
        // 'insertVideo',
        'embedly',
        // 'insertFile',
        'insertTable',
        'emoticons',
        'specialCharacters',
        'insertHR',
        'selectAll',
        // 'print',
        'spellChecker',
        // 'html',
        'help',
        'fullscreen'
    ];
    export default {
        name: 'Editor',
        props:{
            content:String,
        },
        data(){
            const that = this;
            return{
                config: {
                    editor:null,
                    disableRightClick: true,//禁止右键菜单
                    vueIgnoreAttrs: ['class', 'id'],
                    toolbarButtons: tool,
                    // theme: "dark",//主题
                    placeholderText: '无文章？出错啦',
                    language: 'zh_cn', //国际化
                    // imageUploadURL: '', //上传url,会显示loading，即'上传中'的提示
                    // imageUploadParams: { token: '', i: '', ak: '', f: 9 },
                    // fileUploadURL: '',
                    // fileUploadParams: { token: '', i: '', ak: '', f: 9 },
                    // videoUploadURL: '',
                    // videoUploadParams: { token: '', i: '', ak: '', f: 9 },
                    quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'], //快速插入项
                    // toolbarVisibleWithoutSelection: true,//是否开启 不选中模式
                    // disableRightClick: true,//是否屏蔽右击
                    colorsHEXInput: true, //关闭16进制色值
                    toolbarSticky: false, //操作栏是否自动吸顶,
                    // Colors list.
                    colorsBackground: [
                        '#15E67F',
                        '#E3DE8C',
                        '#D8A076',
                        '#D83762',
                        '#76B6D8',
                        'REMOVE',
                        '#1C7A90',
                        '#249CB8',
                        '#4ABED9',
                        '#FBD75B',
                        '#FBE571',
                        '#FFFFFF'
                    ],
                    colorsStep: 6,
                    colorsText: [
                        '#15E67F',
                        '#E3DE8C',
                        '#D8A076',
                        '#D83762',
                        '#76B6D8',
                        'REMOVE',
                        '#1C7A90',
                        '#249CB8',
                        '#4ABED9',
                        '#FBD75B',
                        '#FBE571',
                        '#FFFFFF'
                    ],
                    zIndex: 2000,
                    // height: this.height || '250',
                    // autofocus: true,
                    events: {
                        initialized: function () {

                            that.editor = this;
                            //禁止编辑,双重保证
                            document.getElementsByClassName('fr-element')[0].setAttribute('contenteditable',false)
                            that.editor.edit.off();
                        },
                        blur: () => {

                        },
                        contentChanged: () => {
                            // EditorChange
                            that.EditorChange();
                        }
                    }
                },
            }
        },
        methods:{
            EditorChange(){
                this.$emit('contentChange',this.model)
            }
        }
    }
</script>

<style scoped>
    /deep/.fr-toolbar.fr-top{
        display: none;
    }
    /deep/.fr-second-toolbar{
        display: none;
    }
    /deep/.fr-wrapper > div[style*="z-index:9999;"],
    /deep/.fr-wrapper > div[style*="z-index: 9999;"] {
        height: 0;
        overflow: hidden;
        position: absolute;
        top: -1000000px;
        opacity: 0;
    }
</style>
