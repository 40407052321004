<template>
    <div style="font-size: .3rem;background: #f2f2f2;margin-bottom:1rem;">
        <div>
            <van-swipe @change="onChange" class="my-swipe" :autoplay="3000" indicator-color="green">
                <van-swipe-item v-for="(item,index) in gift.cover" :key="index">
                    <img :src="item" style="height:100%"/>
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">{{ current + 1 }}/{{gift.cover?gift.cover.length:0}}</div>
                </template>
            </van-swipe>
        </div>
        <div style="padding: .2rem .3rem;margin:.3rem .2rem 0;background: white;border-radius: .2rem;">
            <h3>
                {{gift.name}}
            </h3>
            <p style="color:#b5b5b5;padding: .1rem 0;font-size:.25rem">
                {{gift.sub_name||'暂无介绍'}}
            </p>
            <p>
                🌟 {{gift.integral}}
            </p>
        </div>
        <div class="editor_wrap" style="text-align: center">
            <div style="padding:.3rem">— — 详情 — —</div>
            <editor :content="gift.details"></editor>
        </div>
        <div style="position: fixed;bottom: 0;z-index: 6666" @click="exchange">
            <van-button style="width:100vw" type="primary" block>立即换购</van-button>
        </div>
    </div>
</template>

<script>
    import Toast from "vant/lib/toast";

    import { Dialog } from 'vant';
    import editor from './Editor';

    import BindPhone from "./BindPhone/BindPhone";

    export default {
        name: "gift",
        data(){
            return{
                current: 0,
                gift:{},
            }
        },
        components: {
            editor,
            BindPhone
        },
        mounted(){
            this.getGift();
        },
        methods:{
            tests(e){
                console.log(e)
            },
            onChange(index) {
                this.current = index;
            },
            onEditorFocus(event) {
                event.enable(false);
            },
            getGift(){
                this.$axios.get('miniprogram/goodsDetails?goods_id='+this.$route.query.id).then((res)=>{
                    if(res.data.code==='0000')
                    {
                        this.gift=res.data.content.goods;
                    }
                })
            },
            exchange(){
                if(!JSON.parse(localStorage.getItem('is_bind'))){
                    this.$router.push('/login')
                }else{
                    Dialog.confirm({
                        title: '是否兑换',
                    })
                        .then(() => {
                            this.exchangeGift();
                        })
                        .catch(() => {
                            Toast('已取消')
                        });

                }
            },
            exchangeGift(){
                this.$axios.post('miniprogram/exchangeGoods',{
                    goods_id:this.$route.query.id,
                    number:1
                }).then((res)=>{
                    if(res.data.code==='0000')
                    {
                        Toast('兑换成功，可到兑换记录查询')
                    }else{
                        Toast(res.data.content)
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        height:55vh;
        background: #fff;
    }
    .custom-indicator {
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 2px 5px;
        font-size: 12px;
        border-radius: .5rem;
        background: rgba(0, 0, 0, 0.1);
    }
    .editor_wrap{
    }
</style>
