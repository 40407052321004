<template>
    <div>
        <div style="text-align:left;line-height: 50px;padding: 30px">
            <div style="color: #2f3c94;font-size: 20px;font-weight: bold;text-align: center;margin: 10px">
                手机号绑定
            </div>

            <van-form style="width: 100%">
                <van-field v-model="phone" readonly class="content border" placeholder="请输入手机号"/>
                <van-field v-model="dialog.captcha" clearable class="content border" placeholder="请输入验证码">
                    <template #button>
                        <a href="" v-if="!dialog.show_timer" style="color: #2f3c94;"
                           @click.prevent="handleGetVerification">获取验证码</a>
                        <a href="" v-else>{{ dialog.timer }}</a>
                    </template>
                </van-field>
                <van-field v-model="dialog.name" class="content border" placeholder="请输入姓名"/>
                <van-field label-width="auto" name="radio"label="选择身份" class="content border">
                    <template #input>
                        <van-radio-group v-model="radio" direction="horizontal">
                            <van-radio name="0">学生</van-radio>
                            <van-radio name="1">家长</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-button @click="handleBind"
                            round type="info"
                            :loading="dialog.button_loading"
                            size="small"
                            style="background: #2f3c94;
                                    color: #ffffff;
                                    width: inherit;
                                    height: 40px;
                                    margin-top: 10px">立即绑定
                </van-button>

            </van-form>
        </div>

    </div>
</template>

<script>
    import Toast from "vant/lib/toast";

    export default {
        name: "BindPhone",
        data(){
            return{
                dialog:{
                    phone:'',
                    name:'',
                    captcha:'',
                    timer:60,
                    show_timer:false,
                    button_loading:false,
                },
                radio:'0'
            }
        },
        props:{
            phone:String
        },
        created(){

        },
        methods:{
            handleGetVerification(){
                let TEL_REGEXP = /^1\d{10}$/;
                if (!this.phone) {
                    Toast('请输入手机号');
                } else if (!TEL_REGEXP.test(this.phone)) {
                    Toast('请输入正确手机号');
                } else {
                    this.$axios.post('miniprogram/sendVerificationCode', {
                        phone: this.phone,
                    }).then((res) => {
                        //成功发送
                        if (res.data.code === '0000') {
                            // this.$Message.success('短信验证码已发送');
                            Toast('短信验证码已发送');
                            this.dialog.show_timer = true;
                            let st = setInterval(() => {

                                if (this.dialog.timer > 1) {
                                    this.dialog.timer--;
                                } else {
                                    clearInterval(st);
                                    st=null;
                                    this.dialog.show_timer = false;
                                    this.dialog.timer = 60;
                                }
                            }, 1000);
                        } else {
                            Toast(res.data.message)
                        }
                    }, (error) => {
                        Toast(error.response.data.content);
                        this.dialog.button_loading = false;
                    });
                }
            },
            handleBind(){
                this.$axios.post('wechatuser/bindUser', {
                    phone: this.phone,
                    captcha: this.dialog.captcha,
                    name:this.dialog.name,
                    identity:this.radio
                }).then(res => {
                    if(res.data.code==='0000')
                    {
                        Toast('绑定成功！');
                        this.dialog.captcha='';
                        localStorage.setItem('is_bind',true)
                        this.$emit('bindSuccess')
                    }else{
                        Toast(res.data.content);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
